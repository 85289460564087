import React from 'react'
import {LinkIcon, SquareFillIcon} from '@primer/octicons-react'

const ListItem = ({children}) => (
  <li className="flex justify-start items-center gap-2 flex-no-wrap"><div className="flex-shrink-0 self-stretch"><SquareFillIcon verticalAlign="middle" /></div>{children}</li>
)

const List = ({children}) => (
  <ul className="flex flex-col flex-no-wrap">{children}</ul>
)

export { List }

export default ListItem
